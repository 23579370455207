import { format } from 'date-fns'
import { RiTwitterFill, RiInstagramFill, RiFacebookBoxFill } from 'react-icons/ri'
import { ReactSVG } from 'react-svg'
import { Container } from '../Container'
import { Text } from '../Text'
import { Heading } from '../Heading'
import { MailChimpSignup } from '../MailChimpSignup'

import { CustomLink } from '../utils/CustomLink'

export const Footer: React.FC = () => {
  return (
    <footer className="bg-darkbg text-smoke-100 pt-20 pb-40 relative z-8000">
      <Container className="max-w-screen-xl flex justify-between align-start flex-col md:flex-row gap-16">
        <div className="flex-grow">
          <ReactSVG src="/logo-white.svg" title="Good Neighbour" />
          <div className="mt-10">
            <MailChimpSignup />
          </div>
        </div>
        <div className="flex flex-col space-y-10 mt-6 md:mt-0">
          <div className="flex flex-col md:flex-row md:space-x-20 space-x-0 space-y-4 md:space-y-0 text-sm">
            <div>
              <Heading as="p" className="!font-semibold mb-4 uppercase !tracking-wider text-violet-200">
                Company
              </Heading>
              <ul className="space-y-4">
                <li>
                  <CustomLink href="/about" className="no-underline !font-sans">
                    About Good Neighbour
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href="/contact" className="no-underline !font-sans">
                    Contact us
                  </CustomLink>
                </li>
                <li>
                  <a
                    href="https://www.notion.so/Ontario-Tenant-Resources-a38ec80aa8dc4ea4816132039ece1764"
                    className="no-underline !font-sans"
                  >
                    Ontario tenant resources
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.notion.so/Good-Neighbour-Press-Kit-dc4b3e4a9eb44ab891669c45c71639ef"
                    className="no-underline !font-sans"
                  >
                    Press
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <Heading as="p" className="!font-semibold mb-4 uppercase !tracking-wider text-violet-200">
                Legal
              </Heading>
              <ul className="space-y-4">
                <li>
                  <CustomLink href="/privacy" className="no-underline !font-sans">
                    Privacy Policy
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href="/terms" className="no-underline !font-sans">
                    Terms of Service
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href="/cookies" className="no-underline !font-sans">
                    Cookie Policy
                  </CustomLink>
                </li>
                <li>
                  <CustomLink href="/content-policy" className="no-underline !font-sans">
                    Content Policy
                  </CustomLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-col text-smoke-200">
          <div className="flex">
            <Text className="text-lg mr-3">
              <a href="https://instagram.com/goodnbrco" title="Follow @goodnbrco on Instagram">
                <span className="sr-only">Follow @goodnbrco on Instagram</span>
                <RiInstagramFill size="28" />
              </a>
            </Text>
            <Text className="text-lg mr-3">
              <a href="https://www.facebook.com/Good-Neighbour-103714255232221" title="Follow goodnbr on Facebook">
                <span className="sr-only">Follow goodnbr on Facebook</span>
                <RiFacebookBoxFill size="28" />
              </a>
            </Text>
            <Text className="text-lg">
              <a href="https://twitter.com/goodnbrco" title="Follow @goodnbrco on Twitter">
                <span className="sr-only">Follow @goodnbrco on Twitter</span>
                <RiTwitterFill size="28" />
              </a>
            </Text>
          </div>
          <Text className="text-sm text-smoke-400 mt-4">
            © {format(new Date(), 'Y')}{' '}
            <a href="https://reallygood.work" className=" no-underline">
              Really Good Work Inc
            </a>
            .
          </Text>
        </div>
      </Container>
    </footer>
  )
}

Footer.displayName = 'Footer'
