/* eslint-disable react/no-unescaped-entities */
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import FocusTrap from 'focus-trap-react'
import { motion, useCycle, AnimatePresence } from 'framer-motion'

import { useScrollPosition } from 'lib/hooks/useScrollPosition'
import { logout } from 'lib/auth'
import { useStore } from 'lib/state'
import { useWindowLock } from 'lib/hooks/useWindowLock'

import { Logo } from '../Logo'
import { CustomLink } from '../utils/CustomLink'
import { MenuToggle } from './MenuToggle'
import { Search } from '../Search'

const menuItems = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.1 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
}

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: 'spring',
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: 'circle(0px at 32px 32px)',
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}

const background = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 100px 100px)`,
    transition: {
      type: 'spring',
      stiffness: 30,
    },
  }),
  closed: {
    clipPath: 'circle(0px at 32px 32px)',
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
}

export const Nav: React.FC = () => {
  const { pathname, push, asPath } = useRouter()
  const [scrollThreshold, setScrollThreshold] = useState(false)
  const [showNav, setShowNav] = useState(true)
  const { lock, unlock } = useWindowLock()
  // const [scrollValue, setScrollValue] = useState(0)
  const [isOpen, toggleOpen] = useCycle(false, true)
  const overlayOpen = useStore((state) => state.overlayOpen)

  const menuOpen = useStore((state) => state.menuOpen)
  const toggleMenu = useStore((state) => state.toggleMenu)
  const isLoggedIn = useStore((state) => state.isLoggedIn)
  const setUserId = useStore((state) => state.setUserId)
  const toggleLoggedIn = useStore((state) => state.toggleLoggedIn)
  const setNotification = useStore((state) => state.setNotification)
  const resetNotification = useStore((state) => state.resetNotification)
  const toggleAbandonedForm = useStore((state) => state.toggleAbandonedForm)

  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y < -200) {
        setScrollThreshold(true)
      } else {
        setScrollThreshold(false)
      }
      const isShow = currPos.y > prevPos.y
      if (currPos.y > -200) {
        setShowNav(true)
      } else if (isShow !== showNav) {
        setShowNav(isShow)
      }
    },
    [scrollThreshold, showNav],
    undefined,
    false,
    200,
  )

  useEffect(() => {
    const handleResize = () => {
      if (menuOpen && window.innerWidth >= 1024) {
        unlock()
        toggleMenuHandler()
      }
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [menuOpen])

  useEffect(() => {
    function onKeyup(e) {
      if (e.key === 'Escape' && menuOpen) handleMenuToggle()
    }
    window.addEventListener('keyup', onKeyup)
    return () => window.removeEventListener('keyup', onKeyup)
  }, [menuOpen])

  useEffect(() => {
    if (menuOpen) {
      unlock()
      toggleMenuHandler()
    }
  }, [asPath])

  const handleMenuToggle = () => {
    !menuOpen ? lock() : unlock()
    toggleMenuHandler()
  }

  const toggleMenuHandler = () => {
    toggleOpen()
    toggleMenu()
  }

  const handleAddReview = () => {
    toggleAbandonedForm(false)
    resetNotification()
    push('/review')
  }

  const handleLogout = () => {
    setUserId('')
    toggleLoggedIn()
    setNotification({ type: 'success', message: 'Successfully logged out.' })
    push('/')
    logout()
  }

  return (
    <motion.header
      className={clsx(
        'fixed z-9000 top-0 left-0 w-full transform-gpu transition-transform',
        !showNav && !menuOpen ? '-translate-y-14 lg:translate-y-0' : 'translate-y-0',
      )}
      initial="hidden"
      animate="visible"
      variants={{ hidden: { opacity: pathname === '/' ? 0 : 1 }, visible: { opacity: 1 } }}
      transition={{ duration: 1.4, type: 'tween', ease: 'easeInOut' }}
    >
      <>
        <motion.div
          className={clsx(
            'pt-3 pb-4 lg:pt-4 lg:pb-4 text-darker px-3 lg:px-5 relative z-9000 transition lg:flex justify-center bg-white lg:shadow-md',
          )}
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
        >
          <div className="flex flex-col lg:grid lg:grid-cols-12 lg:w-full relative max-w-[2400px]">
            <div className="relative lg:pl-0 text-left col-span-4 flex">
              <motion.div initial={false} animate={menuOpen ? 'open' : 'closed'}>
                <FocusTrap active={menuOpen}>
                  <>
                    <MenuToggle
                      toggle={handleMenuToggle}
                      className={clsx('relative mr-6 top-2 lg:top-3 focus:outline-none z-[9001]')}
                    />

                    <motion.div
                      className="bg-darker bg-opacity-60 fixed w-full left-0 top-0 h-screen z-9000"
                      onClick={handleMenuToggle}
                      variants={background}
                    />

                    <motion.nav
                      variants={sidebar}
                      className={clsx(
                        'bg-white fixed left-0 top-0 px-8 lg:px-12 w-4/5 lg:w-1/2 max-w-[800px] pt-28 h-screen z-9000',
                        menuOpen ? 'pointer-events-auto overflow-y-scroll pb-16' : 'pointer-events-none',
                      )}
                    >
                      <motion.ul
                        variants={variants}
                        className="pt-8 w-full flex flex-col text-left space-y-2 items-start"
                      >
                        <motion.li variants={menuItems}>
                          <CustomLink
                            href="/explore"
                            className={clsx(
                              'text-3xl lg:text-5xl font-semibold no-underline pb-0.5 mb-0.5 border-b-4 hover:border-green-500 transition ease-in duration-150',
                              asPath === '/explore' ? 'border-violet hover:border-violet' : 'border-transparent',
                            )}
                          >
                            Explore.
                          </CustomLink>
                        </motion.li>
                        <motion.li variants={menuItems} className="flex items-center">
                          <CustomLink
                            href="/rentals"
                            className={clsx(
                              'text-3xl lg:text-5xl font-semibold no-underline pb-0.5 mb-0.5 border-b-4 hover:border-green-500 transition ease-in duration-150',
                              !scrollThreshold && asPath === '/rentals'
                                ? 'border-violet hover:border-violet'
                                : 'border-transparent',
                            )}
                          >
                            Rentals.
                          </CustomLink>
                          <span className="text-xs inline-block ml-5 font-oakes rounded-full px-4 pt-1.5 pb-1 font-oakes leading-relaxed lg:leading-snug mb-6 bg-violet-50 text-violet inline-block tracking-wider relative top-2">
                            Launching Soon
                          </span>
                        </motion.li>
                        <motion.li variants={menuItems}>
                          <CustomLink
                            href="/review"
                            className={clsx(
                              'text-3xl lg:text-5xl font-semibold no-underline pb-0.5 mb-0.5 border-b-4 hover:border-green-500 transition ease-in duration-150',
                              !scrollThreshold && asPath === '/review'
                                ? 'border-violet hover:border-violet'
                                : 'border-transparent',
                            )}
                          >
                            Write new review.
                          </CustomLink>
                        </motion.li>
                        <motion.li variants={menuItems}>
                          <CustomLink
                            href="/about"
                            className={clsx(
                              'text-3xl lg:text-5xl font-semibold no-underline pb-0.5 mb-0.5 border-b-4 hover:border-green-500 transition ease-in duration-150',
                              !scrollThreshold && asPath === '/about'
                                ? 'border-violet hover:border-violet'
                                : 'border-transparent',
                            )}
                          >
                            About.
                          </CustomLink>
                        </motion.li>
                        {isLoggedIn ? (
                          <>
                            <motion.li variants={menuItems} whileHover={{ x: 1 }}>
                              <CustomLink
                                href="/dashboard"
                                className={clsx(
                                  'text-3xl lg:text-5xl font-semibold no-underline pb-0.5 mb-0.5 border-b-4 hover:border-green-500 transition ease-in duration-150',
                                  !scrollThreshold && asPath === '/dashboard'
                                    ? 'border-violet hover:border-violet'
                                    : 'border-transparent',
                                )}
                              >
                                Dashboard.
                              </CustomLink>
                            </motion.li>
                          </>
                        ) : null}
                      </motion.ul>
                      <div className="mt-16">
                        <motion.ul variants={variants} className="space-y-2">
                          <motion.li variants={menuItems}>
                            <CustomLink href="/contact" className="text-xl text-violet no-underline">
                              Contact us.
                            </CustomLink>
                          </motion.li>
                          <motion.li variants={menuItems}>
                            <CustomLink
                              href="https://www.notion.so/Good-Neighbour-Press-Kit-dc4b3e4a9eb44ab891669c45c71639ef"
                              className="text-xl text-violet no-underline"
                            >
                              Press.
                            </CustomLink>
                          </motion.li>
                          <motion.li variants={menuItems}>
                            <CustomLink href="/terms" className="text-xl text-violet no-underline">
                              Terms of Service.
                            </CustomLink>
                          </motion.li>
                          <motion.li variants={menuItems}>
                            <CustomLink href="/privacy" className="text-xl text-violet no-underline">
                              Privacy Policy.
                            </CustomLink>
                          </motion.li>
                          <motion.li variants={menuItems}>
                            <CustomLink
                              href="https://www.notion.so/Ontario-Tenant-Resources-a38ec80aa8dc4ea4816132039ece1764"
                              className="text-xl text-violet no-underline"
                            >
                              Tenant Resources
                            </CustomLink>
                          </motion.li>

                          <motion.li variants={menuItems}>
                            {!isLoggedIn ? (
                              <CustomLink href="/login" className="text-xl text-violet no-underline">
                                Login.
                              </CustomLink>
                            ) : (
                              <button onClick={handleLogout} className="font-oakes text-xl text-violet no-underline">
                                Logout.
                              </button>
                            )}
                          </motion.li>
                        </motion.ul>
                      </div>
                    </motion.nav>
                  </>
                </FocusTrap>
              </motion.div>
              <AnimatePresence>
                <div className="absolute left-10">
                  <Logo showSmall={!scrollThreshold} />
                </div>
              </AnimatePresence>
              <div className="lg:hidden absolute right-0 top-1">
                {isLoggedIn ? (
                  <>
                    <CustomLink
                      href="/dashboard"
                      className={clsx(
                        'text-sm lg:text-sm no-underline mr-6 text-darkGreen',
                        pathname === '/dashboard' && 'border-b border-blue-600',
                      )}
                    >
                      Dashboard.
                    </CustomLink>
                  </>
                ) : (
                  <>
                    {pathname === '/review' ? (
                      <CustomLink
                        href="/explore"
                        className={clsx(
                          'text-xs font-oakes rounded-full pt-3 pb-2 px-8lg:w-auto  transition duration-500 ease-in-out motion-reduce:transition-none  tracking-wide',
                          'bg-darkGreen text-white hover:bg-opacity-80 no-underline',
                        )}
                      >
                        Explore.
                      </CustomLink>
                    ) : (
                      <CustomLink
                        href="/login"
                        className={clsx(
                          'text-xs font-oakes rounded-full pt-3 pb-2 px-8 lg:w-auto  transition duration-500 ease-in-out motion-reduce:transition-none tracking-wide',
                          'bg-darkGreen text-white hover:bg-opacity-80 no-underline',
                          pathname === '/login' && 'border-b border-blue-600',
                        )}
                      >
                        Sign In.
                      </CustomLink>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="lg:max-w-screen-lg hidden lg:block flex-1 mt-2 lg:mt-0 mb-6 lg:mb-0 col-span-5">
              <Search shouldDisplayBackground />
            </div>
            <div className="hidden lg:flex items-center justify-end col-span-3 relative text-right">
              {isLoggedIn ? (
                <>
                  <CustomLink
                    href="/dashboard"
                    className={clsx(
                      'text-xl lg:text-sm no-underline mr-6 text-darkGreen',
                      pathname === '/dashboard' && 'border-b border-violet',
                    )}
                  >
                    Dashboard.
                  </CustomLink>
                  <button
                    onClick={handleLogout}
                    className="text-xs font-oakes rounded-full pt-3 pb-2 px-6 lg:w-auto  transition duration-500 ease-in-out motion-reduce:transition-none hidden lg:block tracking-wide bg-darkGreen text-white hover:bg-opacity-80 no-underline"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <>
                  {pathname === '/review' ? (
                    <CustomLink
                      href="/explore"
                      className={clsx(
                        'text-sm font-oakes rounded-full pt-3 pb-2 px-16 sm:px-8 lg:w-auto  transition duration-500 ease-in-out motion-reduce:transition-none hidden lg:block tracking-wide',
                        'bg-darkGreen text-white hover:bg-opacity-80 no-underline',
                      )}
                    >
                      Explore.
                    </CustomLink>
                  ) : (
                    <CustomLink
                      href="/login"
                      className={clsx(
                        'text-sm font-oakes rounded-full pt-3 pb-2 px-16 sm:px-8 lg:w-auto  transition duration-500 ease-in-out motion-reduce:transition-none hidden lg:block tracking-wide',
                        'bg-darkGreen text-white hover:bg-opacity-80 no-underline',
                        pathname === '/login' && 'border-b border-blue-600',
                      )}
                    >
                      Sign In.
                    </CustomLink>
                  )}
                </>
              )}
            </div>
          </div>
        </motion.div>
        <div
          className={clsx(
            'fixed w-full top-14 block lg:hidden px-2 py-2 z-8000',
            !scrollThreshold && pathname === '/' ? '' : 'bg-white',
          )}
        >
          <Search shouldDisplayBackground />
        </div>
      </>
    </motion.header>
  )
}

Nav.displayName = 'Nav'
