/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ReCAPTCHA from 'react-google-recaptcha'
import { useState } from 'react'
import { RiErrorWarningLine } from 'react-icons/ri'
import { useForm } from 'react-hook-form'
import cookie from 'js-cookie'

import { Checkbox, Text, Heading, Input, GoogleAuth, FacebookAuth } from 'components'
import { useStore } from 'lib/state'
import { SignupService, LoginService } from 'lib/authUser'
import { login } from 'lib/auth'

const RECAPTCHA_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_KEY

type Props = {
  submitCallback: (...args: any) => void
}

export const SignupSection: React.FunctionComponent<Props> = ({ submitCallback }: Props) => {
  const [formError, setFormError] = useState<string>('')
  const [valid, setValid] = useState(false)
  const [verify, setVerify] = useState(false)
  const [isLogin, setIsLogin] = useState(true)
  const [email, setEmail] = useState('')
  const [magicCodeVal, setMagicCodeVal] = useState('')
  const showCookieBanner = useStore((state) => state.showCookieBanner)
  const toggleCookieBanner = useStore((state) => state.toggleCookieBanner)
  const setNotification = useStore((state) => state.setNotification)
  const toggleLoggedIn = useStore((state) => state.toggleLoggedIn)
  const { handleSubmit, control } = useForm({
    defaultValues: {
      email: '',
      password: '',
      contactMe: false,
    },
    mode: 'onSubmit',
  })

  const handleCaptchaChange = () => {
    setValid(true)
  }

  const handleSocialLogin = (id: string, token: string, email: string) => {
    login({
      id: id,
      token: token,
    })

    setNotification({ type: 'success', message: 'Logged in successfully. Posting your listing.' })
    toggleLoggedIn()
    setEmail(email)
    submitCallback()
  }

  const handleFormSubmit = handleSubmit(async ({ email, password, contactMe }) => {
    setFormError('')
    if (isLogin) {
      try {
        await LoginService({ email, password })
        if (showCookieBanner) {
          cookie.set('__gn_accepted_cookies', true, { expires: 365 })
          toggleCookieBanner(false)
        }
        setEmail(email)
        setNotification({ type: 'success', message: 'Logged in successfully. Posting your listing.' })
        toggleLoggedIn()
        submitCallback()
        setFormError('')
      } catch (err) {
        setNotification({ type: 'error', message: 'Error Logging in. Please try again.' })
        setFormError((err as any).errors)
      }
    } else if (valid) {
      try {
        await SignupService({ email, password, contactMe })
        if (showCookieBanner) {
          cookie.set('__gn_accepted_cookies', true, { expires: 365 })
          toggleCookieBanner(false)
        }
        setNotification({ type: 'success', message: 'Signed up successfully.' })
        setEmail(email)
        setVerify(true)
        setFormError('')
      } catch (err) {
        setEmail('')
        setFormError((err as any).errors)
      }
    } else {
      setFormError('You must accept captcha.')
    }
  })

  return (
    <div className="col-span-12 grid grid-cols-12 lg:bg-white lg:rounded-xl gap-6">
      {!verify ? (
        <>
          <div className="col-span-12">
            <Text className="text-sm md:text-lg">Contributing to Good Neighbour is anonymous and easy.</Text>
          </div>
          <div className="col-span-12 lg:col-span-6">
            <div className="lg:w-full flex flex-col">
              <GoogleAuth label="Sign up with Google" handleCallback={handleSocialLogin} />
              <FacebookAuth label="Sign up with Facebook" handleCallback={handleSocialLogin} />
            </div>
            <div className="mt-10 flex items-center justify-center text-smoke-600 border-t border-smoke-300 relative">
              <Text className="bg-white absolute -top-2 text-xs font-oakes px-3">Or log in with email</Text>
            </div>
            <form onSubmit={handleFormSubmit} className="mt-3">
              <div className="space-y-4">
                {isLogin ? (
                  <div className="lg:mt-6">
                    <Input
                      type="email"
                      label="Email Address"
                      name="email"
                      placeholder="you@email.com"
                      rules={{ required: 'Email Address is required for signup' }}
                      control={control}
                    />
                    <Input
                      type="password"
                      label="Password"
                      name="password"
                      rules={{
                        required: 'Password is required for login',
                        minLength: {
                          value: 10,
                          message: 'Password must be minimum 10 characters.',
                        },
                        maxLength: {
                          value: 128,
                          message: 'Password must be maximum 128 characters.',
                        },
                        pattern: {
                          value: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{10,64})/,
                          message: 'Password must contain at least 1 capital letter, 1 special character and 1 number',
                        },
                      }}
                      control={control}
                    />
                    <div className="flex items-center">
                      <button
                        type="submit"
                        className="font-oakes tracking-wide bg-darker shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm mt-2"
                      >
                        Sign in
                      </button>
                      {formError && (
                        <div
                          className="bg-pinker-200 inline-block py-2 px-3 rounded-lg inline-flex items-center text-pinker-600 mt-2 ml-2"
                          role="alert"
                        >
                          <RiErrorWarningLine className="text-pinker-600" size="16" />
                          <Text className="text-sm ml-1.5 text-pinker-600">
                            <strong>Error:</strong> {formError}
                          </Text>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="lg:mt-6 space-y-4">
                      <Input
                        type="email"
                        label="Email Address"
                        name="email"
                        control={control}
                        rules={{ required: 'Email Address is required for signup' }}
                      />
                      <Input
                        type="password"
                        label="Password"
                        name="password"
                        rules={{
                          required: 'Password is required for login',
                          minLength: {
                            value: 10,
                            message: 'Password must be minimum 10 characters.',
                          },
                          maxLength: {
                            value: 128,
                            message: 'Password must be maximum 128 characters.',
                          },
                          pattern: {
                            value: /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{10,64})/,
                            message:
                              'Password must contain at least 1 capital letter, 1 special character and 1 number',
                          },
                        }}
                        control={control}
                      />
                      <Checkbox
                        control={control}
                        name="contactMe"
                        label="Sign me up for the occasional email about Good Neighbour."
                      />
                      <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={handleCaptchaChange} />
                      <button
                        type="submit"
                        className="font-oakes tracking-wide bg-darker shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm mt-2"
                      >
                        Sign up
                      </button>
                    </div>
                  </>
                )}
              </div>
            </form>
            <button
              onClick={() => {
                setIsLogin(!isLogin)
                setFormError('')
              }}
              className="text-violet-500 text-left text-sm mt-4"
            >
              {isLogin ? 'No Account? Create new account.' : 'Use your existing account.'}
            </button>
          </div>
        </>
      ) : (
        <div className="col-span-12 lg:col-span-10 text-center lg:col-start-2 py-16 ">
          <div className="flex-1">
            <Heading as="h3" className="text-darker text-2xl sm:text-5xl mb-3 font-bold">
              Verify your account.
            </Heading>

            <Text>We just sent an email to {email}.</Text>

            <Text className="text-sm text-smoke-900 mt-4">
              Click the link in the email to verify your account and post your review.
            </Text>
          </div>
          <div className="flex-1">
            <Text className="text-smoke-800 text-sm">Check your spam folder if you can't find the email.</Text>
            <Text className="text-smoke-900 text-sm mt-4">
              <button onClick={() => setVerify(false)} className="font-oakes tracking-wide text-violet text-sm mt-2">
                Sign in with a different email address or send another code.
              </button>
            </Text>
          </div>
        </div>
      )}
    </div>
  )
}
