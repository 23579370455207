import { Heading, Text, RatingInput, Slider, Checkbox, Button } from 'components'
import { Label } from 'components/Form/Label'
import { useForm, Controller, useWatch } from 'react-hook-form'
import FocusTrap from 'focus-trap-react'
import { IoMdClose } from 'react-icons/io'

const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || ''

const padDigits = (digits) => {
  const desiredLength = 3
  const actualLength = digits.length

  if (actualLength >= desiredLength) {
    return digits
  }

  const amountToAdd = desiredLength - actualLength
  const padding = '0'.repeat(amountToAdd)

  return padding + digits
}

const removeLeadingZeros = (number) => number.replace(/^0+([0-9]+)/, '$1')

const addDecimalToNumber = (number, separator) => {
  const centsStartingPosition = number.length - 2
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition))
  const cents = number.substring(centsStartingPosition)
  return dollars + separator + cents
}

export const toCurrency = (value, separator = '.') => {
  const digits = getDigitsFromValue(value)
  const digitsWithPadding = padDigits(digits)
  return addDecimalToNumber(digitsWithPadding, separator)
}

const separator = '.'

type Props = {
  filterCallback: (data?: any) => void
  closeFilters: () => void
  filters?: {
    rating?: number
    minPrice?: number
    maxPrice?: number
    gasIncluded?: boolean
    waterIncluded?: boolean
    hydroIncluded?: boolean
    laundryIncluded?: boolean
    dishwasherIncluded?: boolean
    airConditioningIncluded?: boolean
    parkingAvailable?: boolean
    petsWelcome?: boolean
    accessibilityFriendly?: boolean
  }
}

export const SearchFilters = ({ filterCallback, filters, closeFilters }: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    register,
  } = useForm({
    defaultValues: {
      rating: filters?.rating || 0,
      minPrice: filters?.minPrice || 100,
      maxPrice: filters?.maxPrice || 5000,
      gasIncluded: filters?.gasIncluded || false,
      waterIncluded: filters?.waterIncluded || false,
      hydroIncluded: filters?.hydroIncluded || false,
      laundryIncluded: filters?.laundryIncluded || false,
      dishwasherIncluded: filters?.dishwasherIncluded || false,
      airConditioningIncluded: filters?.airConditioningIncluded || false,
      parkingAvailable: filters?.parkingAvailable || false,
      petsWelcome: filters?.petsWelcome || false,
      accessibilityFriendly: filters?.accessibilityFriendly || false,
    },
  })

  const clearFilters = () => {
    reset()
    handleCallback(null)
  }

  const handleCallback = (data) => {
    filterCallback(data)
  }

  const onSubmit = handleSubmit((data) => {
    handleCallback(data)
  })

  const minPriceValue = useWatch({
    control,
    name: 'minPrice',
    defaultValue: filters?.minPrice || 100,
  })

  const maxPriceValue = useWatch({
    control,
    name: 'maxPrice',
    defaultValue: filters?.maxPrice || 5000,
  })

  const updateMinValue = (value) => {
    const valueAsCurrency = toCurrency(value, separator)
    setValue('minPrice', valueAsCurrency)
  }

  const updateMaxValue = (value) => {
    const valueAsCurrency = toCurrency(value, separator)
    setValue('maxPrice', valueAsCurrency)
  }

  return (
    <div className="bg-white lg:rounded-tl-md lg:rounded-bl-md shadow-xl h-screen lg:h-90  border border-smoke-200 overflow-hidden">
      <FocusTrap>
        <div className="relative h-full">
          <header className="text-center border-b py-2.5">
            <Heading as="h2" className="text-darker text-xl">
              Filters
            </Heading>
          </header>
          <form
            onSubmit={onSubmit}
            autoComplete="off"
            className="flex flex-col overflow-y-scroll h-full pb-40 lg:pb-32"
          >
            <div className="px-4">
              <div className="border-b mt-3 pt-1.5 pb-4">
                <Controller
                  control={control}
                  name="rating"
                  render={({ field: { onChange, value } }) => (
                    <RatingInput
                      value={value}
                      label="Rating"
                      onChange={onChange}
                      error={errors.rating}
                      className="text-base sm:text-base mb-0.5 !font-medium"
                      size={24}
                    />
                  )}
                />
              </div>
              <div className="border-b mt-3 pt-1.5 pb-10">
                <div className="mb-4">
                  <Heading as="p" className="font-oakes font-semibold text-base">
                    Rent price
                  </Heading>
                  <Text className="text-sm text-smoke-600 font-oakes">Choose monthly rent price range</Text>
                </div>
                <div className="w-full">
                  <div>
                    <Slider
                      min={0}
                      max={10000}
                      minValue={minPriceValue}
                      maxValue={maxPriceValue}
                      minValueCallback={(value) => setValue('minPrice', value)}
                      maxValueCallback={(value) => setValue('maxPrice', value)}
                    />
                  </div>
                  <div className="flex space-x-9 mt-6">
                    <div role="group">
                      <Label label="Minimum Price" name="minPrice" className="mb-2 font-medium" />
                      <div className="flex rounded-xl">
                        <div className="p-3 rounded-l-md bg-darkbg border border-smoke-500 text-white">$</div>
                        <input
                          inputMode="decimal"
                          type="text"
                          {...register('minPrice')}
                          onChange={updateMinValue}
                          className="appearance-none border border-l-0 px-3
                          pt-3.5
                          pb-4 w-full bg-white placeholder-smoke-500 rounded-r-md focus:outline-none focus:ring-1 border-smoke-500 focus:ring-darker"
                        />
                      </div>
                    </div>
                    <div role="group">
                      <Label label="Maximum Price" name="maxPrice" className="mb-2 font-medium" />
                      <div className="flex rounded-xl">
                        <div className="p-3 rounded-l-md bg-darkbg border border-smoke-500 text-white">$</div>
                        <input
                          inputMode="decimal"
                          type="text"
                          {...register('maxPrice')}
                          onChange={updateMaxValue}
                          className="appearance-none border border-l-0 px-3
                          pt-3.5
                          pb-4 w-full bg-white shadow-inner placeholder-smoke-500 rounded-r-md focus:outline-none focus:ring-1 border-smoke-500 focus:ring-darker"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-b mt-3 pt-1.5 pb-4">
                <Heading as="p" className="font-oakes font-semibold text-base">
                  Utilities
                </Heading>
                <Text className="text-sm text-smoke-600 font-oakes">Select all that apply </Text>
                <div className="flex flex-wrap mt-3">
                  <label htmlFor="accessibilityFriendly" className="block relative mr-3 mb-3">
                    <input
                      type="checkbox"
                      {...register('accessibilityFriendly')}
                      id="accessibilityFriendly"
                      className="next:border-blue-600 next:border-1 next:ring-1 next:ring-blue-600 appearance-none h-0 absolute"
                    />
                    <span className="block border border-darker text-sm px-3.5 pt-2.5 pb-2 rounded-full font-oakes">
                      Accessibility Friendly
                    </span>
                  </label>
                  <label htmlFor="gasIncluded" className="block relative mr-3 mb-3">
                    <input
                      type="checkbox"
                      {...register('gasIncluded')}
                      id="gasIncluded"
                      className="next:border-blue-600 next:border-1 next:ring-1 next:ring-blue-600 appearance-none h-0 absolute"
                    />
                    <span className="block border border-darker text-sm px-3.5 pt-2.5 pb-2 rounded-full font-oakes">
                      Gas Included
                    </span>
                  </label>
                  <label htmlFor="waterIncluded" className="block relative mr-3 mb-3">
                    <input
                      type="checkbox"
                      {...register('waterIncluded')}
                      id="waterIncluded"
                      className="next:border-blue-600 next:border-1 next:ring-1 next:ring-blue-600 appearance-none h-0 absolute"
                    />
                    <span className="block border border-darker text-sm px-3.5 pt-2.5 pb-2 rounded-full font-oakes">
                      Water Included
                    </span>
                  </label>
                  <label htmlFor="hydroIncluded" className="block relative mr-3 mb-3">
                    <input
                      type="checkbox"
                      {...register('hydroIncluded')}
                      id="hydroIncluded"
                      className="next:border-blue-600 next:border-1 next:ring-1 next:ring-blue-600 appearance-none h-0 absolute"
                    />
                    <span className="block border border-darker text-sm px-3.5 pt-2.5 pb-2 rounded-full font-oakes">
                      Electricity Included
                    </span>
                  </label>
                  <label htmlFor="dishwasherIncluded" className="block relative mr-3 mb-3">
                    <input
                      type="checkbox"
                      {...register('dishwasherIncluded')}
                      id="dishwasherIncluded"
                      className="next:border-blue-600 next:border-1 next:ring-1 next:ring-blue-600 appearance-none h-0 absolute focus:next:ring-2 focus:next:ring-blue-600"
                    />
                    <span className="block border border-darker text-sm px-3.5 pt-2.5 pb-2 rounded-full font-oakes">
                      Dishwasher Included
                    </span>
                  </label>
                  <label htmlFor="airConditioningIncluded" className="block relative mr-3 mb-3">
                    <input
                      type="checkbox"
                      {...register('airConditioningIncluded')}
                      id="airConditioningIncluded"
                      className="next:border-blue-600 next:border-1 next:ring-1 next:ring-blue-600 appearance-none h-0 absolute"
                    />
                    <span className="block border border-darker text-sm px-3.5 pt-2.5 pb-2 rounded-full font-oakes">
                      Air Conditioning Included
                    </span>
                  </label>
                  <label htmlFor="parkingAvailable" className="block relative mr-3 mb-3">
                    <input
                      type="checkbox"
                      {...register('parkingAvailable')}
                      id="parkingAvailable"
                      className="next:border-blue-600 next:border-1 next:ring-1 next:ring-blue-600 appearance-none h-0 absolute"
                    />
                    <span className="block border border-darker text-sm px-3.5 pt-2.5 pb-2 rounded-full font-oakes">
                      Parking Available
                    </span>
                  </label>
                </div>
              </div>
              <div className="mt-3 pt-1.5 pb-4 flex flex-col items-start space-y-3 ">
                <Checkbox control={control} name="petsWelcome" label="Pets welcome" />
                {/* <Checkbox control={control} name="accessibilityFriendly" label="Accessibility Friendly" /> */}
              </div>
            </div>

            <div className="space-x-4 p-4 bg-white w-full border-t fixed lg:absolute bottom-0">
              <button type="button" onClick={clearFilters} className="font-oakes text-sm text-smoke-600 tracking-tight">
                Clear
              </button>
              <button type="submit" className="font-oakes pt-2.5 pb-2 px-4 bg-darker text-white rounded-full text-sm">
                Apply Filters
              </button>
            </div>
          </form>
          <button className="absolute top-3 right-3" onClick={closeFilters} title="Close Filters">
            <IoMdClose size="24" />
          </button>
        </div>
      </FocusTrap>
    </div>
  )
}
